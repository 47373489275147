import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useState, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import teamPhoto from './../documents/welcome-to-team.jpg';
import Alert from 'react-bootstrap/Alert';


export default function JobForm() {
    const [full_name, setFullName] = useState("");
    const [e_mail, setEmail] = useState("");
    const [letter, setLetter] = useState("");
    const [show, setShow] = useState(false);
    const input_file = useRef(null);

    let curriculum_vitae = null;

    const resetInputs = () => {
        setShow(false)
        setEmail('');
        setFullName('');
        setLetter('');
        input_file.current.value = null;
    };

    const handleFileChange = event => {
        curriculum_vitae = event.target.files && event.target.files[0];
        if (!curriculum_vitae) {
          return;
        }
    };

    const onSubmit = async(event) => {
        const formData = new FormData();
        formData.append("full_name", full_name);
        formData.append("e_mail", e_mail);
        formData.append("letter", letter);
        formData.append("curriculum_vitae", curriculum_vitae);
        event.preventDefault();
        setShow(true);
        try {
            const response = await axios({
            method: 'post',
            url: "api/job/",
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
            });
            console.log(response.status);
        } catch(error) {
            console.log(error.response)
        }
    }

    return (
        <Container id='posao' className='pt-5'>
            <h2 className='py-4 text-center'>Pridruži nam se!</h2>
            <Row className='p3-5 align-items-center' xs={1} sm={1} md={2}>
                <Col className='mb-3'>
                    <Image fluid src={teamPhoto} className='border-0 m-0 p-0 rounded-5'
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    />
                </Col>
                <Col className='pb-3'>
                    <Form onSubmit={onSubmit} id='posao' className='box-atelje'>
                        <Row>
                        <Form.Group className="mb-3 pt-3">
                            <Form.Label>Ime i Prezime:</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Unesite ime i prezime" 
                                value={full_name}
                                onChange={(e) => setFullName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Email adresa:</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Unesite email adresu" 
                                value={e_mail}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tekst:</Form.Label>
                            <Form.Control as="textarea" 
                                placeholder="Unesite sadržaj vaše prijave (motivaciono pismo)"
                                value={letter}
                                onChange={(e) => setLetter(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Odaberite file:</Form.Label>
                            <Form.Control 
                                type="file"  
                                accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
                                ref={input_file}
                                onChange={handleFileChange}
                                required
                            />
                        </Form.Group>
                    </Row>
                    <Row className='justify-content-center m-auto'>
                        <Alert show={show} variant="success">
                            <p style={{margin:'0px'}}>Vaša prijava je uspješna, naš tim će vas kontaktirati uskoro.</p>
                            <div className="d-flex justify-content-end">
                            <Button onClick={resetInputs} variant="outline-success">
                                Zatvori
                            </Button>
                            </div>
                        </Alert>
                    </Row>
                    <Row className='justify-content-center m-auto'>
                        <Button variant='danger' type="submit" className="mb-3 btn-atelje-sec text-centar" size='lg'>
                            Pošalji
                        </Button>
                    </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}