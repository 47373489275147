import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import React,{useState,useEffect} from 'react';


function AboutUs() {
  const [data, setData] = useState(null);
  useEffect(() => {
  axios.get('api/aboutus/')
      .then((response) => {
      setData(response.data);
    })
    .catch(error => {
        console.log(error.response)
    });
  }, []);
  if (!data) return null;

  return (
    <Container id='onama' fluid className='pt-5 bg-atelje'>
        <Container>
            <h2 className="pt-4 align-items-center text-center">O nama</h2> 
              {data.map((data, index) => {
                  return(
                    <Row key={index} xs={1} sm={1} md={2} className='pt-3 pb-5'>
                        <Col className='py-3 align-item-center text-center'>
                            <h5>{data.headline}</h5>
                            <p style={{
                            textAlign: "justify",
                            textJustify: "inter-word"
                            }}>{data.description}</p> 
                        </Col>
                        <Col className='py-3 align-item-center text-center'>
                        <iframe height='300px' width='100%' src={data.video_link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </Col>
                    </Row>
                    );
                })
            }
        </Container>
    </Container>
  );
}


export default AboutUs;