import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { MdLocationOn, MdCall, MdAccessTime } from 'react-icons/md';
import Image from 'react-bootstrap/Image';
import pinLocations from '../documents/AteljeClubPins.png';


function Contact() {
  const [data, setData] = useState(null);

  useEffect(() => {
  axios.get('api/location/')
      .then((response) => {
        response.data.forEach(element => {
            element.hours = (element.hours.split(","));
        });
        
      setData(response.data);
    })
    .catch(error => {
        console.log(error.response)
    });
  }, []);

  if (!data) return null;
  
  return (
        <Container fluid id='kontakt' className='pt-5'>
        <Container className='my-5 box-atelje justify-content-center text-center'>
            <Container className='pb-3 px-0'>
                <a href='https://www.google.com/maps/search/atelje+club/@44.8120516,15.8496133,15z/data=!3m1!4b1' target="_blank" rel="noreferrer">
                  <Image src={pinLocations} style={{height:'100%', width: '100%', borderRadius: '25px', objectFit: 'scale-down'}}/></a>
            </Container>
            <Row xs={1} sm={1} md={3} className='py-5'>
                {data.map((data, index) => {
                  return(
                        <Col key={index}>
                            <h4>{data.name}</h4>
                            <h5><MdLocationOn />&nbsp; {data.address}</h5>
                            <h5><MdCall />&nbsp; {data.phone}</h5> 
                            <h5 className='pb-1 pt-3'><MdAccessTime />&nbsp; Radno vrijeme:</h5>
                            {data.hours.map((hours, index) => {
                                return(
                                    <h6 key={index}>
                                        {hours}
                                    </h6>
                                )}
                            )}
                        </Col>
                        );
                    })
                }
            </Row>
    </Container>
    </Container>
  );
}


export default Contact;