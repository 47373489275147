import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaTripadvisor, FaYoutube } from "react-icons/fa";
import './ACtheme.css';

export default function SocialMedia() {

    const goToYT = () => {
        window.open('https://www.youtube.com/channel/UCAG_AWhi4eARf7Fnj6jZojw', '_blank');
    }
    const goToFB = () => {
        window.open('https://www.facebook.com/ateljeclub.bihac/', '_blank');
    }
    const goToIG = () => {
        window.open('https://www.instagram.com/ateljeclub/', '_blank');
    }
    const goToTA = () => {
        window.open('https://www.tripadvisor.com/Restaurant_Review-g295389-d11548531-Reviews-Atelje_Club_Centar-Bihac_Una_Sana_Canton_Federation_of_Bosnia_and_Herzegovina.html', '_blank');
    }

    return (
        <Container fluid id='social-media'>
            <Row className="text-center pt-4 bg-atelje" >
                <h5>
                Pratite nas na drustvenim mrezama!
                </h5>
            </Row>
            <Row className="text-center bg-atelje" >
                <h1>
                <FaYoutube className='social-media-icon' onClick={goToYT}/>&nbsp;&nbsp;&nbsp;
                <BsFacebook className='social-media-icon' onClick={goToFB}/>&nbsp;&nbsp;&nbsp;
                <BsInstagram className='social-media-icon' onClick={goToIG}/>&nbsp;&nbsp;&nbsp;
                <FaTripadvisor className='social-media-icon' onClick={goToTA}/>
                </h1>
            </Row>
        </Container>
  );
}