import Container from 'react-bootstrap/Container';
import ReactSimplyCarousel from 'react-simply-carousel';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { MdFastfood } from 'react-icons/md';
import Pdf from './../documents/menu.pdf';
import "./ACtheme.css";


export default function Product() {
    const [data, setData] = useState("");
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    useEffect(() => {
        axios.get('api/menu/')
        .then((response) => {
        setData(response.data);
    })
    .catch(error => {
        console.log(error.response)
    });
    }, []);

    if (!data) return null;

    return (
        <Container fluid id='meni' className='pt-5 pb-5 py-0 m-0' style={{backgroundColor: 'transparent'}}>
            <Container className='align-item-center text-center'>
                <h2 className="py-4">Izdvojeno iz ponude!</h2>
            </Container>
            <ReactSimplyCarousel
                    activeSlideIndex={activeSlideIndex}
                    onRequestChange={setActiveSlideIndex}
                    itemsToShow={1}
                    itemsToScroll={1}
                    forwardBtnProps={{
                    //here you can also pass className, or any other button element attributes
                    className: 'acrsc',
                    children: <FaAngleRight/>,
                    }}
                    backwardBtnProps={{
                    //here you can also pass className, or any other button element attributes
                    className: 'acrsc',
                    children: <FaAngleLeft/>,
                    }}
                    responsiveProps={
                        [
                            {minWidth: 1500, itemsToShow: 4}, 
                            {minWidth: 1300, maxWidth: 1500, itemsToShow: 3}, 
                            {minWidth: 992, maxWidth: 1300, itemsToShow: 2},
                            {minWidth: 300, maxWidth: 992, itemsToShow: 1}
                        ]
                    }
                    speed={400}
                    easing="linear"
                >
                {data.map((data, index) => {
                    return (
                        <div key={index} style={{margin: '0px', padding: '0px', width: '250px', height: '375px'}}>
                            <Card className="border-0 p-0 m-0 rounded bg-atelje" style={{width: '100%', height: '100%'}}>
                                <Image fluid src={data.image ? data.image : undefined} className='border-0 m-0 p-0 rounded-0'
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }}
                                    />
                            </Card>
                        </div>
                    );
                    })
                }
            </ReactSimplyCarousel>
            <Container className='align-item-center text-center'>
                <h2 className="pt-5 pb-4">Detaljnu ponudu možete pogledati u aktuelnom Meniju.</h2>
                <a href={Pdf} target="_blank" rel="noreferrer">
                    <Button variant='danger' className='btn-atelje'>
                        <span className='btn-txt'>Meni</span><MdFastfood className='btn-icon'/>
                    </Button>
                </a>
            </Container>         
        </Container>
    );
}