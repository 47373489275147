import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import './ACtheme.css';

export default function LandingPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get('api/landing/')
      .then((response) => {
      setData(response.data);
    })
    .catch(error => {
        console.log(error.response)
    });
  }, []);

  if (!data) return null;

  return (
      <Container fluid className='pt-5 pb-0 px-0 align-item-center text-center m-0 ' id='pocetna'>
          <Carousel >
            {data.map((data, index) => {
                return (
                    <Carousel.Item key={index}>
                      <Image fluid src={data.foreground_image} width='100%'/>
                    </Carousel.Item>
                );
            })}
          </Carousel>
      </Container>
  );
}