import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './ACtheme.css';
import Logo from './../documents/ateljeLogov2.png';


export default function Header() {

  return (
    <Navbar collapseOnSelect fixed='top' expand='lg' variant='dark' className='fg-atelje bg-atelje'>
        <Container>
            <Navbar.Brand href="/">
              Atelje<img src={Logo} alt='...' width={'20px'} height={'20px'} className='align-baseline'></img>Club
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='justify-content-end flex-grow-1 pe-3'>
                <NavDropdown title="Nazovi i naruči" id="nav-atelje">
                  <NavDropdown.Item as='a' href="tel:+38761765383" >Atelje Club Prekounje</NavDropdown.Item>
                  <NavDropdown.Item as='a' href="tel:+38761259153" >Atelje Club Harmani</NavDropdown.Item>
                  <NavDropdown.Item as='a' href="tel:+38762776426" >Atelje Club Centar</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as='a' href='/#novosti' className='fg-nav-atelje'>Novosti</Nav.Link>
                <Nav.Link as='a' href='/#meni' className='fg-nav-atelje'>Naša ponuda</Nav.Link>
                <Nav.Link as='a' href='/#onama' className='fg-nav-atelje'>O nama</Nav.Link>
                <Nav.Link as='a' href='/#posao' className='fg-nav-atelje'>Posao</Nav.Link>
                <Nav.Link as='a' href='/#kontakt' className='fg-nav-atelje'>Kontakt</Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}
