import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import React,{useState,useEffect} from 'react';
import './ACtheme.css';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

export default function Updates() {
    const [data, setData] = useState("");

    useEffect(() => {
    axios.get('api/update/')
        .then((response) => {
        setData(response.data);
    })
    .catch(error => {
        console.log(error.response)
    });
    }, []);

    if (!data) return null;

    return (
        <Container id='novosti' className='pt-5 text-center' style={{backgroundColor: 'transparent'}}>
            <Row style={{backgroundColor: 'transparent'}}>
                <h2 className="py-4">Novosti</h2>
            </Row>
            <Row xs={1} sm={1} md={3}>
                {data.map((data, index) => {
                    return (
                        <Col key={index} className='mb-3'>
                            <Card className="p-0 border-0 rounded bg-atelje" key={index} style={{width: '100%', height:'100%'}}>
                                <Image fluid src={data.foreground_image ? data.foreground_image : undefined} className='border-0 m-0 p-0 rounded-0'
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }}
                                    />
                            </Card>
                        </Col>
                    );
                    })
                }
            </Row> 
        </Container>
    );
}