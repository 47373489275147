import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaTripadvisor, FaYoutube, FaAngleUp } from "react-icons/fa";
import "./scrollToTop.css";


const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const goToYT = () => {
        window.open('https://www.youtube.com/channel/UCAG_AWhi4eARf7Fnj6jZojw', '_blank');
    }
    const goToFB = () => {
        window.open('https://www.facebook.com/ateljeclub.bihac/', '_blank');
    }
    const goToIG = () => {
        window.open('https://www.instagram.com/ateljeclub/', '_blank');
    }
    const goToTA = () => {
        window.open('https://www.tripadvisor.com/Restaurant_Review-g295389-d11548531-Reviews-Atelje_Club_Centar-Bihac_Una_Sana_Canton_Federation_of_Bosnia_and_Herzegovina.html', '_blank');
    }
    
    return (
        <>
        <Container className="top-to-btm">
            {" "}
            {showTopBtn && (
                <FaAngleUp
                    className="btn btn-danger icon-position icon-style"
                    onClick={goToTop}
                />
            )}{" "}
        </Container>
        <Container fluid className='social-media align-items-center text-center'>
            <Row className='yt-bg'><FaYoutube  
                    onClick={goToYT} 
                    />
            </Row>
            <Row className='fb-bg'><BsFacebook 
                    onClick={goToFB} 
                    />
            </Row>
            <Row className='ig-bg'><BsInstagram 
                    onClick={goToIG} 
                    />
            </Row>
            <Row className='ta-bg'><FaTripadvisor 
                    onClick={goToTA}
                    />
            </Row>
        </Container>
        </>
    );
};
export default ScrollToTop;