import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FaTimesCircle, 
  FaChevronCircleRight, 
  FaChevronCircleLeft,
} from 'react-icons/fa';
import './wsp-gallery.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

export default function WSPGallery() {

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState('');

  useEffect(() => {
    axios.get('api/gallery/')
        .then((response) => {
          setGalleryImages(response.data);
        })
        .catch(error => {
            console.log(error.response)
        });
    }, []);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( galleryImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 );
  };

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1);
  };
  
  return (
    <Container fluid id='galerija'>
      {openModal && 
        <Container fluid className='sliderWrap'>
          <FaTimesCircle className='btnClose' onClick={handleCloseModal}  />
          <FaChevronCircleLeft className='btnPrev' onClick={prevSlide} />
          <FaChevronCircleRight className='btnNext' onClick={nextSlide} />
          <Container className='fullScreenImage'>
            <img src={galleryImages[slideNumber].foreground_image} alt='' />
          </Container>
        </Container>
      }
      <Row xs={1} sm={1} md={4} className='pb-5'>
        {
          galleryImages && galleryImages.map((slide, index) => {
            return(
              <Col className='border-0 m-0 p-0'
                key={index}
                onClick={ () => handleOpenModal(index) }
              >
                <Image fluid thumbnail src={slide.foreground_image} className='border-0 m-0 p-0 rounded-0'
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Col>
            )
          })
        }
      </Row>
    </Container>
  )
}