import React,{useState,useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import axios from 'axios';



export default function BgImage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get('api/background/')
      .then((response) => {
      setData(response.data[0]);
    })
    .catch(error => {
        console.log(error.response)
    });
  }, []);

  if (!data) return null;

  var backgroundStyle1 = {
    backgroundImage: `url(${data.background_image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    position: 'fixed',
    padding: '0px', 
    margin: '0px',
    zIndex: '0',
  };

  return (
    <Container
        fluid 
        style={
          backgroundStyle1
          }>
    </Container>
  );
};