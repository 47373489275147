import React from 'react';
import './App.css';
import Header from "./components/Header";
import JobForm from './components/JobForm';
import BgImage from './components/BgImage';
import Container from 'react-bootstrap/Container';
import Footer from './components/Footer';
import LandingPage from "./components/LandingPage";
import Contact from './components/Contact';
import Product from './components/Product';
import Updates from './components/Updates';
import ScrollToTop from './components/ScrollToTop';
import AboutUs from './components/AboutUs';
import WSPGallery from './components/WSPGallery';
import SocialMedia from './components/SocialMedia';


function App() {
  return (
    <main>
      <Header />
      <BgImage />
      <ScrollToTop />
      <Container fluid style={{position:'relative', color: '#ec173a', padding: '0px', margin: '0px'}}>
      <LandingPage />
      <Updates />
      <Product />
      <AboutUs />
      <WSPGallery />
      <JobForm/>
      <Contact />
      <SocialMedia />
      <Footer />
      </Container>
    </main>
  );
}

export default App;
