import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ACtheme.css';


export default function Footer() {
    return (
    <footer className="bg-atelje" style={{height:'10vh'}}>
        <Container fluid>
            <Row className="text-center pt-3 pb-4" >
                <Col><p>© 2022 Copyright: Atelje Club all rights reserved.</p></Col>
            </Row>
        </Container>
    </footer>
  );
}
